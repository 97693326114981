import React, { useEffect, useState } from "react";
import "./home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { useWindowSize } from "react-use";
import {
  accordionListItems,
  bannerData,
  counterData,
  newsData,
} from "../../data/home";
import { leftArrow, rightArrow } from "../../images";
import CommonCta from "../../components/commonCta/commonCta";
import SectionContent from "../../components/SectionContent/SectionContent";
import NewsCard from "../../components/NewsCard/NewsCard";
import VerticalAccordion from "../../components/VerticalAccordion/VerticalAccordion";
import { ourCompanyUrl } from "../../components/helpers/constant-words";
import config from "../../config/config";
import http from "../../helpers/http";

const HomeDynamic = () => {
  const { width } = useWindowSize();
  const [isMarginLeft, setIsMarginLeft] = useState(0);
  const [bannerData, setBannerData] = useState([]);
  const [statsData, setStatsData] = useState([]);
  const [sectionData, setSectionData] = useState({});
  const [accordianBanners, setAccordianBanners] = useState([]);
  const [featuredNews, setNewsCategories] = useState([]);
  const base_url = config.api_url;
  const image_url = config.image_url;

  const fetchBannerData = async () => {
    const { data } = await http.get(`${base_url}/homepage/banners`);
    if (data) {
      setBannerData(data);
    }
  };

  const fetchVentureBanners = async () => {
    const { data } = await http.get(`${base_url}/business-ventures/banners`);
    if (data) {
      setAccordianBanners(
        data.map((it) => ({
          id: it._id,
          mainTitle: it.title,
          img: `${image_url}${it.accordion_desktop_banner.url}`,
          mbImg: `${image_url}${it.accordion_mobile_banner.url}`,
          alt: `${it.alt_tag_accordian}`,
          desc: [it.featured_line],
          url: it.cta,
        }))
      );
    }
  };

  const fetchStatsData = async () => {
    const { data } = await http.get(`${base_url}/homepage/stats`);
    if (data) {
      setStatsData(data);
    }
  };

  const fetchDividerSectionData = async () => {
    const { data } = await http.get(`${base_url}/homepage`);
    if (data) {
      setSectionData(data[0]);
    }
  };

  const fetchNewsCategories = async () => {
    const { data } = await http.get(`${base_url}/newscentre/categories`);
    if (data) {
      setNewsCategories(
        data.map((it) => ({
          id: it._id,
          img: `${image_url}${it.thumbnail.url}`,
          alt: it.thumbnail.name,
          title: it.name,
          desc: it.description,
        }))
      );
    }
  };

  useEffect(() => {
    fetchBannerData();
    fetchVentureBanners();
    fetchStatsData();
    fetchDividerSectionData();
    fetchNewsCategories();
  }, []);

  const bannerList = bannerData.map((item, i) => (
    <SwiperSlide key={i}>
      <img
        src={
          width > 767
            ? `${image_url}${item.desktop_banner.url}`
            : `${image_url}${item.mobile_banner.url}`
        }
        alt={item?.alt_tag}
        className="banner_img"
      />
      <div className="my_container">
        <div className="content_wrapper">
          <h2 className="banner_heading">{item.title}</h2>
          <p className="banner_desc">{item.description}</p>
          {item.cta_text && item.cta && (
            <CommonCta
              linkCta={true}
              linkText={item.cta_text}
              link={item.cta}
              darkbg={true}
            />
          )}
        </div>
      </div>
    </SwiperSlide>
  ));

  const counterList = statsData.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="counter_container">
        {/* <Counteritem
          startCount={item.numb}
          endCount={item.numb}
          duration={0}
          countTitle={item.title}
        /> */}
        <div className="common_counter_wrapper">
          <div className="common_actual_count">
            <span className="numb">{item.number}</span>
            <span className="data_wrapper">
              <span className="plus">+</span>
              <span
                className="title"
                dangerouslySetInnerHTML={{ __html: item.name }}
              ></span>
            </span>
          </div>
        </div>
        <img
          src={`${image_url}${item.image.url}`}
          alt={item.image.name}
          className="counter_img"
        />
      </div>
    </SwiperSlide>
  ));

  const adjustMargins = () => {
    const windowsize = window.innerWidth;
    const secwidth = document.querySelector(".my_container").offsetWidth;
    const calcwidth = windowsize - secwidth;

    if (secwidth) {
      setIsMarginLeft(calcwidth / 2);
    }
  };

  useEffect(() => {
    adjustMargins();
    // Adjust margins when window resizes
    const handleResize = () => adjustMargins();
    window.addEventListener("resize", handleResize);
    // Adjust margins on window load
    window.addEventListener("load", handleResize);
    // Clean up event listeners when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", adjustMargins);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  return (
    <>
      <section className="home_sec1">
        <div className="banner_slider_wrapper">
          <Swiper
            slidesPerView={1}
            grabCursor={true}
            loop={true}
            modules={[Navigation, Autoplay]}
            navigation={{
              nextEl: ".arrow_wrapper_right",
              prevEl: ".arrow_wrapper_left",
            }}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            className="banner_slider"
          >
            {bannerList}
            <div className="banner_arrows">
              <div className="arrow_wrapper_left">
                <img src={leftArrow} alt="left arrow" className="left_arrow" />
              </div>
              <div className="arrow_wrapper_right">
                <img
                  src={rightArrow}
                  alt="right arrow"
                  className="right_arrow"
                />
              </div>
            </div>
          </Swiper>
        </div>
      </section>

      <section className="home_sec2">
        <SectionContent
          leftHeading={`01. ${sectionData?.section1?.heading}`}
          rightHeading={sectionData?.section1?.title}
          desc={sectionData?.section1?.description}
          cta={sectionData?.section1?.title}
          ctaText={sectionData?.section1?.cta_text}
          link={ourCompanyUrl}
        />
      </section>

      <section className="home_sec3">
        <div
          className="my_container"
          style={{ marginLeft: `${isMarginLeft}px` }}
        >
          <div className="counter_content_wrapper">
            <Swiper
              slidesPerView={1.72}
              spaceBetween={10}
              // centeredSlides={true}
              allowTouchMove={false}
              loop={counterData.length !== 0 ? true : false}
              modules={[Autoplay]}
              speed={6000}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 0.9,
                },
                768: {
                  slidesPerView: 1.4,
                },
                992: {
                  slidesPerView: 1.8,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1194: {
                  slidesPerView: 2.3,
                },
                1280: {
                  slidesPerView: 2.4,
                },
                1366: {
                  slidesPerView: 2.4,
                },
                1440: {
                  slidesPerView: 2.6,
                },
                1536: {
                  slidesPerView: 2.6,
                },
                1600: {
                  slidesPerView: 2.3,
                },
                1700: {
                  slidesPerView: 2.4,
                },
                1920: {
                  slidesPerView: 2.2,
                  spaceBetween: 10,
                },
              }}
              className="counter_slider"
            >
              {counterList}
            </Swiper>
          </div>
        </div>
      </section>

      <section className="home_sec4">
        <SectionContent
          leftHeading={`02. ${sectionData?.section2?.heading}`}
          desc={sectionData?.section2?.description}
        />
        <div className="my_container">
          <VerticalAccordion listItems={accordianBanners} />
        </div>
      </section>

      <section className="home_sec5">
        <SectionContent
          leftHeading="03. NEWS CENTRE"
          cta={width > 991 ? true : false}
          ctaText="View All"
        />
        <NewsCard dataList={featuredNews} />
        {width <= 991 && (
          <div className="my_container">
            <CommonCta linkCta={true} linkText="View All" link={() => false} />
          </div>
        )}
      </section>
    </>
  );
};

export default HomeDynamic;
