import { images, press07Pdf } from "../images";

export const newsCenterData = [
  // {
  //   id: 1,
  //   type: "external-link",
  //   img: images.Media12.image,
  //   alt: images.Media12.alt,
  //   title: "February, 2024",
  //   desc: "Indian Street Premier League’s Chennai Singams Unveils its Brand Identity (Online News 9)",
  //   link: "https://onlinenews9.in/business/indian-street-premier-leagues-chennai-singams-unveils-its-brand-identity/",
  // },
  {
    id: 2,
    type: "external-link",
    img: images.Media11.image,
    alt: images.Media11.alt,
    title: "March, 2024",
    desc: "More than monetary benefits, players would be more excited about the opportunity – Rajdip Gupta, Co-owner of Chennai Singams (Sports Mint)",
    link: "https://sportsmintmedia.com/exclusive-more-than-monetary-benefits-players-would-be-more-excited-about-the-opportunity-rajdip-gupta-co-owner-of-chennai-singams/",
  },
  {
    id: 3,
    type: "external-link",
    img: images.Media10.image,
    alt: images.Media10.alt,
    title: "March, 2024",
    desc: "Creating Opportunities For Tennis-Ball Cricketers: How Chennai Singams' Rajdip Gupta Looks To Build ISPL T10 (Times Now News)",
    link: "https://www.timesnownews.com/sports/cricket/creating-opportunities-for-tennis-ball-cricketers-how-chennai-singams-rajdip-gupta-looks-to-build-ispl-t10-article-108298509",
  },
  {
    type: "video",
    id: 4,
    img: images.Media02.image,
    alt: images.Media02.alt,
    title: "July, 2024",
    desc: "How He Built a Bootstrapped ₹10,000 Cr Business! (YourStory)",
    video: "https://www.youtube.com/embed/-sCvA9FPzpQ?si=Iu3LTfZI4zdFRuHp",
  },
  {
    id: 5,
    type: "external-link",
    img: images.Media08.image,
    alt: images.Media08.alt,
    title: "July, 2024",
    desc: "Bootstrapping a Rs 10,000 crore company: Route Mobile’s journey (Your Story) ",
    link: "https://yourstory.com/2024/07/bootstrapping-10000-crore-company-route-mobiles-journey",
  },
  {
    type: "video",
    id: 6,
    img: images.Media07.image,
    alt: images.Media07.alt,
    title: "August, 2024",
    desc: "Office Boys At Rajdip Gupta's Route Mobile Made CRORES From ESOPs After Firm's IPO | Founder Diaries (Mint)",
    video: "https://www.youtube.com/embed/TqgGVTNL_ow?si=C7jPigqySuYGc_1A",
  },
  {
    id: 7,
    type: "external-link",
    img: images.Media15.image,
    alt: images.Media15.alt,
    title: "August, 2024",
    desc: "Mumbai: Clear Bridge Ventures LLP Acquires Malad West IT Park For ₹335 Crore In Major Real Estate Deal (Free Press Journal)",
    link: "https://www.freepressjournal.in/mumbai/mumbai-clear-bridge-ventures-llp-acquires-malad-west-it-park-for-335-crore-in-major-real-estate-deal",
  },
  {
    id: 8,
    type: "external-link",
    img: images.Media16.image,
    alt: images.Media16.alt,
    title: "August, 2024",
    desc: "Clear Bridge Ventures buys IT Park, 2-acre land in Mumbai’s Malad for Rs 335 cr (The Economic Times)",
    link: "https://economictimes.indiatimes.com/industry/services/property-/-cstruction/clear-bridge-ventures-buys-it-park-2-acre-land-in-mumbais-malad-for-rs-335-cr/articleshow/112930853.cms?from=mdr",
  },
  {
    id: 9,
    type: "external-link",
    img: images.Media17.image,
    alt: images.Media17.alt,
    title: "August, 2023",
    desc: "Indian Family Business Awards 2022 Live",
    link: "https://www.linkedin.com/posts/routemobile_best-governance-award-at-the-india-family-activity-7098705850311086080-WPHS?utm_source=share&utm_medium=member_desktop",
  },
  {
    type: "video",
    id: 10,
    img: images.Media04.image,
    alt: images.Media04.alt,
    title: "March, 2022",
    desc: "Proficorn: Profitable and Built to Last Startup | Route Mobile | #FoundersUnfiltered",
    video: "https://www.youtube.com/embed/GDVuMvJGsMU?si=NApYtoIstMYuvRib",
  },
  {
    type: "video",
    id: 11,
    img: images.Media05.image,
    alt: images.Media05.alt,
    title: "August, 2021",
    desc: "From Bootstrap to IPO ft. Rajdip Gupta, Founder, Route Mobile",
    video: "https://www.youtube.com/embed/gl3IzhrVMr8?si=uOpc1_fbPyxRx7sR",
  },
  {
    type: "video",
    id: 12,
    img: images.Media06.image,
    alt: images.Media06.alt,
    title: "November, 2021",
    desc: "Jason Bryan interviews Rajdip Gupta on CPaaS and the future of Messaging | ROCCO",
    video: "https://www.youtube.com/embed/J9m2I1bBJcE?si=xlUUb7t0s5mww1VS",
  },
  {
    type: "video",
    id: 13,
    img: images.Media03.image,
    alt: images.Media03.alt,
    title: "December, 2021",
    desc: "A View From The Top - Dario Betti, CEO MEF, Talks to Rajdip Gupta, Group CEO Route Mobile Ltd",
    video: "https://www.youtube.com/embed/1bFWCD3zGcU?si=STCtGBhkD9wqAqc2",
  },
  {
    type: "video",
    id: 14,
    img: images.Media01.image,
    alt: images.Media01.alt,
    title: "December, 2020",
    desc: "Fireside chat - Rajdip Gupta, Founder, MD and Group CEO of Route Mobile",
    video: "https://www.youtube.com/embed/r42YsxaynYQ?si=X0qMP17BvVl-NqfP",
  },
  {
    id: 15,
    type: "external-link",
    img: images.Media09.image,
    alt: images.Media09.alt,
    title: "December, 2020",
    desc: "How Route Mobile became a darling of investor (Forbes)",
    link: "https://www.forbesindia.com/article/take-one-big-story-of-the-day/how-route-mobile-became-a-darling-of-investors/64931/1",
  },
  {
    id: 16,
    type: "external-link",
    img: images.Media13.image,
    alt: images.Media13.alt,
    title: "May, 2019",
    desc: "The T20 Mumbai League is a huge opportunity for local talent to get noticed.”- says Prithvi Shaw, Captain, North Mumbai Panthers (APN News)",
    link: "https://www.apnnews.com/the-t20-mumbai-league-is-a-huge-opportunity-for-local-talent-to-get-noticed-says-prithvi-shaw-captain-north-mumbai-panthers/",
  },
  {
    id: 17,
    type: "external-link",
    img: images.Media14.image,
    alt: images.Media14.alt,
    title: "May, 2019",
    desc: "Novotel Hotels & Resorts is the Lead Sponsor of North Mumbai Panthers at the T-20 Mumbai League (Hospibuz)",
    link: "https://hospibuz.com/novotel-hotels-resorts-is-the-lead-sponsor-of-north-mumbai-panthers-at-the-t-20-mumbai-league/",
  },
];

export const newsCenterAwardsData = [
  {
    id: 1,
    img: images.award01.image,
    alt: images.award01.alt,
    desc: "Rajdip Gupta was awarded the Trailblazer CEO Award at the Corporate Leadership Awards 2024.",
  },
  {
    id: 2,
    img: images.award05.image,
    alt: images.award05.alt,
    desc: `Rajdip Gupta was featured in the Top 25 Most Influential People in the ROCCO 100 - 2024 report.`,
  },
  {
    id: 3,
    img: images.award09.image,
    alt: images.award09.alt,
    desc: "Mr. Sandip Gupta was awarded with Emerging Businessman of the year by NBT Utsav 2024.",
  },
  {
    id: 4,
    img: images.award02.image,
    alt: images.award02.alt,
    desc: "Rajdip Gupta received the Most Promising Business Leaders of Asia award for 2023-24.",
  },
  {
    id: 5,
    img: images.award03.image,
    alt: images.award03.alt,
    desc: "The company won the 'Best Governance' award in the Mega category at the India Family Business Awards 2023.",
  },
  {
    id: 6,
    img: images.award04.image,
    alt: images.award04.alt,
    desc: "Rajdip Gupta and Sandip Gupta were honored as 'Bootstrapped Entrepreneurs of the Year 2023",
  },
  {
    id: 7,
    img: images.award06.image,
    alt: images.award06.alt,
    desc: `Mr. Rajdip Gupta was conferred the titles "CEO of the Year" and "Cloud Innovator of the Year 2023" by ET Ascent.`,
  },
  {
    id: 8,
    img: images.award08.image,
    alt: images.award08.alt,
    desc: "Hurun India recognized Rajdip and Sandip Gupta as among India's Top 200 self-made entrepreneurs of the Millennia 2023.",
  },
  {
    id: 9,
    img: images.award07.image,
    alt: images.award07.alt,
    desc: `The company won the ET Ascent Business Leader of the Year 2023 awards for "Best Use of Cloud Services by a Telecom Company" and "Best Enterprise Cloud Offering" (IT Sector).`,
  },
];

export const newsCenterPressData = [
  {
    id: 1,
    slug: "proximus",
    img: images.press05.image,
    heroImg: images.pressMb05.image,
    alt: images.press05.alt,
    title: "May 8, 2024",
    desc: "Proximus",
    innerTitle: "Route Mobile embarks on a new strategic direction by becoming a part of the Proximus Group. This will help the company expand its global footprint and product portfolio.",
    innerContent: [
      `<span>Mumbai, 08 May, 2024:</span>`,
      `Route Mobile Limited (Route Mobile), a global player in Communications Platform as a Service (CPaaS), has now become a part of the Proximus Group, a provider of digital services and communication solutions operating in Belgium and international markets, through a strategic acquisition by the latter.`,
      `In July 2023, Proximus Group signed a definitive agreement with the founding shareholders of Route Mobile to acquire 57.56%1 of Route Mobile through Proximus Opal2 for INR 59,244 million (EUR 643.0 million3) cash consideration, which corresponds to a price per share of INR 1,626.404. Some of the founding shareholders of Route Mobile subsequently committed to reinvest EUR 299.6 million in Proximus Opal, resulting in a stake of 12.72%, expected to be completed in the next few weeks.`,
      `Over the past years, Proximus Group already built up a significant presence in the CPaaS and digital identity markets. The complementary expertise and global reach of Route Mobile (a leader in CPaaS) and their US-based affiliate – Telesign (leader in Digital Identity), will allow to reap the benefits of scale, accelerate growth as a truly worldwide group and generate shareholder value.`,
      `Following receipt of regulatory clearances and finalization of the MTO process, the transaction has been completed today. The MTO stake, coupled with the shares acquired from the founding shareholders, brings the total shareholding of Proximus Opal in Route Mobile to 82.70%5.`,
      `<span>A strategic direction for global expansion</span>`,
      `With this acquisition, Route Mobile has now become part of the Proximus Group, which becomes one of the world’s largest CPaaS players by messaging volume. This is a strategic direction for Route Mobile, which will help in entering mature markets like the USA and Europe, expanding product portfolio and unlocking identified synergies with Telesign.`,
      `Rajdip Gupta will continue as the Chief Executive Officer of Route Mobile and will also spearhead the CPaaS business, globally.`,
      `Talking about this development, <span>Guillaume Boutin, Chief Executive Officer, Proximus</span>, said, “Route Mobile is an exciting new addition to the Proximus Group’s family of international companies, which includes Telesign and BICS. This expansion enhances our CPaaS capabilities, completes our geographical reach and aligns with our expectations of creating substantial value. It’s a strategic move that we believe will contribute positively to Proximus’s risk profile and support our goals for sustainable growth and cash flow. I really look forward to embracing this new phase in our strategic development.”`,
      `<span>Rajdip Gupta, Chief Executive Officer, Route Mobile Limited</span>, said, “Route Mobile completes 20 years of its establishment this year. It has been a remarkable journey from Bootstrap to IPO to a Profitable Unicorn. This development takes us one step further to our goal of becoming a $1 billion revenue company”. He also said, “We look forward to working with Telesign and creating a global leader in digital communications and digital identity within the Proximus Group.`,
      `<span>About Route Mobile Limited (www.routemobile.com) (BSE: 543228; NSE: ROUTE)</span>`,
      `Established in 2004, Route Mobile Limited (“RML”) is a cloud communications platform service provider catering to enterprises, over-the-top (OTT) players, and mobile network operators (MNOs). RML’s portfolio comprises solutions in Business Messaging, Voice, Email, SMS filtering, analytics, and monetization. RML has a diverse enterprise client base across various industries, including social media companies, banks and financial institutions, e-commerce entities, and travel aggregators. RML is headquartered in Mumbai, India, with a global presence in Asia Pacific, the Middle East, Africa, Europe, and the Americas.`,
      `<span>About Proximus Group</span>`,
      `Proximus Group (Euronext Brussels: PROX), headquartered in Brussels, is a provider of future-proof connectivity, IT and digital services, operating in the Benelux and global markets. The Group is actively engaged in building a connected world and in ensuring the trustworthiness, security and sovereignty of digital services, to the benefit of society.`,
      `Proximus has the ambition to build the #1 gigabit network for Belgium and plays a central role in creating inspiring digital ecosystems, while fostering an engaging culture and empowering ways of working. Building upon these strengths, Proximus aims to contribute to an inclusive and sustainable digital society, delight customers with an unrivalled experience and achieve profitable growth both locally and internationally to deliver long-term value for stakeholders.`,
      `In Belgium, Proximus’ core products and services are offered under the Proximus, Mobile Vikings, Scarlet and Proximus NXT brands. The Group is also active in Luxembourg (Tango and Telindus Luxembourg) and in the Netherlands (Telindus Netherlands). The Group’s international carrier activities are managed by BICS, a leading international communications enabler, one of the key global voice carriers and the leading provider of mobile data services worldwide. With Telesign, the Group also encompasses a fast-growing leader in authentication and digital identity services, serving the world’s largest internet brands, digital champions and cloud native businesses.`,
      `With 11,654 employees, imbued with Proximus’ Think Possible mindset and all engaged to offer a superior customer experience, the Group realized an underlying Group revenue of EUR 6,042 million end-2023.`,
      `<span>Additional Resources</span>`,
      `<ul>
      <li>Follow Route Mobile on Linkedin: <a href="https://in.linkedin.com/company/routemobilelimited" target="_blank">https://in.linkedin.com/company/routemobilelimited</a></li>
      <li>Follow Route Mobile on Twitter: <a href="https://twitter.com/route_mobile" target="_blank">https://twitter.com/route_mobile</a></li>
      <li>Become a fan of Route Mobile: <a href="https://www.facebook.com/Routemobilelimited" target="_blank">https://www.facebook.com/Routemobilelimited</a></li>
      </ul>`,
      `For more information, visit <a href="www.proximus.com" target="_blank">www.proximus.com</a> & <a href="www.proximus.be" target="_blank">www.proximus.be.</a>`,
      `<span>For more information, contact:</span>`,
      `Mr. Sumit Zawar <br/> Email : <a href="mailto:press@routemobile.com">press@routemobile.com<a>`,
      `Tel: <a href="tel:+912240337676">+91-22-40337676</a>`,
      `Ms. Isha Pawar <br/> Email : <a href="mailto:isha@conceptpr.com">isha@conceptpr.com<a>`,
      `Tel: <a href="tel:+917738344952">+91 77383 44952</a>`,
      `<ol>
      <li>On a fully diluted basis, Taking into account evolutions in the total number of shares since July 2023, this corresponds to 57.71% of the current total of shares.</li>
      <li>Proximus Opal is a subsidiary of the Proximus Group holding 100% of Telesign and following this acquisition also the majority stake in Route Mobile</li>
      <li>At an exchange rate of 0.0108578 EUR/INR.</li>
      <li>Based on the 10-day volume-weighted average price (VWAP) of Route Mobile of INR 1,626.40.</li>
      <li>On a fully diluted basis. As a result, the public shareholding in Route Mobile falls below the minimum level required as per Indian regulations, i.e., 25% of the aggregate shareholding. Proximus Opal will ensure that it satisfies this requirement within 12 months in order to hold a stake of maximum 75% in Route Mobile.</li>
      </ol>`,
      ``,
    ],
  },
  {
    id: 2,
    slug: "sumeet-dekhale-to-lead-chennai-singams",
    img: images.press01.image,
    heroImg: images.pressMb01.image,
    alt: images.press01.alt,
    title: "March  6, 2024",
    desc: "Sumeet Dekhale to Lead Chennai Singams",
    innerTitle: "Sumeet Dhekale to Lead Chennai Singams Pride in Debut Indian Street Premier League",
    innerContent: [
      `Chennai Singams, co-owned by business stalwarts Rajdip Gupta, Sandip Gupta, and actor Suriya Sivakumar, today announced a proficient left-handed batsman, Sumeet Dhekale, as the team captain to lead the Chennai Singams pride. Dhekale was acquired by the team for a whopping ₹19 Lakhs making him the most expensive player in the Singams Squad.`,
      `Hailing from Mumbai, Sumeet Dhekale, also known as ‘Bahubali,’ has made a mark in the tennis cricket world. Originally from Nalasopara, Mumbai he successfully transitioned from conventional leather ball to tennis ball cricket, excelling in various sports after joining the NSP Team. Despite being a BA graduate, his primary focus has always been cricket. Dhekale gives the credit for his success to his father, Varadh Dhekale, a cricket enthusiast, who played a pivotal role in fostering the family’s passion for the sport. `,
      `This 35-year-old player has two decades of experience in the field and will be leading the team along with Sanjay Kanojjiya, as the Vice – Captain. Chennai Singams secured 16 players for a total of ₹96.4 Lakhs from various regions across the country in the ISPL auction. The formidable lineup of players other than the captain and vice-captain include Dilip Binjwa, Sagar Ali, Farhat Ahmed, Farman Khan, R Thavith Kumar, Venkatachalpathi Vignesh, Aniket Sanap, Bablu Patil, Harish Parmar, Ketan Mhatre, Rajdeep Jadeja, Vedant Mayekar, Vishwanath Jadhav, and Pankaj Patel.`,
      `Talking about the acquisition, <span>Rajdip Gupta, Co-owner, Chennai Singams</span> said, “This is a moment of pride for the Chennai Singams family. With the increasing interest in professional tennis tournaments and the significant participation in tennis cricket, the decision to appoint Sumeet as the leader is a testament to our belief that he will elevate the team to new heights through his intelligence and experience in the game.”`,
      `<span>Sumeet Dhekale, Captain, Chennai Singams</span> said, “Leading the Chennai Singams team as the captain is a tremendous honour. Leagues like the Indian Street Premier League provide excellent exposure and aid in the growth of individuals, allowing them to showcase their skills. As per our practice sessions and the stellar performances of our players, I am 100% sure we’re here to win and become the first-ever ISPL champions.”`,
      `The ISPL is set to commence from March 6th to 15th, 2024, at Dadoji Kondadev Stadium, Thane.`,
    ],
  },
  {
    id: 3,
    slug: "chennai-singams-secures-major-jersey-sponsorships",
    img: images.press02.image,
    heroImg: images.pressMb02.image,
    alt: images.press02.alt,
    title: "March  4, 2024",
    desc: "Chennai Singams secures major jersey sponsorships",
    innerTitle: "Chennai Singams secures major jersey sponsorships ahead of ISPL debut",
    innerContent: [
      `The inaugural season of the ISPL will get underway from 6 to 15 March 2024.`,
      `<span>Mumbai:</span> As excitement grows for the inaugural edition of the Indian Street Premier League (ISPL), Chennai Singams, the #RoartoRule cricket franchise, has secured significant jersey sponsorships across sports, hospitality, finance, banking, energy and food & beverages categories. ibis Hotels from the hospitality segment is among the major jersey sponsors for the franchise which is making its debut in the much-anticipated ISPL league. The inaugural edition of the Indian Street Premier League will get underway from 6 to 15 March 2024.`,
      `Apart from ibis Hotels, the Chennai-based team, led by serial entrepreneurs Rajdip Gupta, Sandip Gupta, and actor Suriya Sivakumar, has signed lucrative contracts with Nuwama Wealth, Hell Energy Drink, Yes Bank, TYKA and Route Energy, demonstrating their dedication to excellence and sportsmanship.`,
      `In response to these historic partnerships, Chennai Singams co-owner Rajdip Gupta said, "The team and I are very elated to join hands with such esteemed global brands. Such sponsorships and collaborations are important to increase team visibility and boost overall performance. Looking forward to a great season this year and tying up with numerous brands in the years to come."`,
      `ibis, a globally recognised hospitality brand known for its modern accommodations and exceptional service, has joined forces with Chennai Singams as one of their premier jersey sponsors. Their association represents a shared commitment to excellence and performance, laying the groundwork for an electrifying debut in the ISPL.`,
      `Chennai Singams also welcomes Nuvama Wealth, a well-known financial services provider, as a jersey sponsor. This collaboration represents the convergence of sporting and financial expertise, as both organizations strive for success and growth in their respective fields.`,
      `Apart from that, Chennai Singams has joined hands with Hell Energy Drink as another valued jersey sponsor, adding vigor and vitality to their roster. Hell Energy Drink, with its invigorating blend and dynamic branding, complements the team's energy and dynamism on the pitch.`,
      `In addition, the ISPL cricket franchise strengthens its financial base by securing Yes Bank as a key jersey sponsor. As India's leading private sector bank, Yes Bank's partnership demonstrates stability, growth, and a common vision for cricket success.`,
      `Chennai Singams has also partnered with TYKA, a well-known sports apparel brand known for its quality and innovation, to expand its roster of prestigious jersey sponsors. The TYKA association demonstrates a dedication to performance excellence and athletic prowess, which is consistent with the team's philosophy.`,
      `Chennai Singams has also joined hands with Route Energy, an emerging solar energy solution provider with expertise in efficiency products, solutions, and consulting services. The brand offers consulting services in the renewable energy and energy efficiency sectors from technological, industrial, policy, socioeconomic, and collaborative perspectives.`,
      `ibis and ibis Styles India director of operations Tejus Jose said, “It’s going to be a great association with Chennai Singams as the popular game of cricket enters into different exciting formats. We, as one of the official jersey sponsors and partners of the franchise, wish all the luck and success to the team ahead of its ISPL debut.”`,
      `As ISPL gears up for its exhilarating T10 tennis ball cricket tournament, the Chennai Singams vow to enthrall spectators with their dynamic displays on the transformed streets of Mumbai, now turned into stadiums.`,
    ],
  },
  {
    id: 4,
    slug: "chennai-singams-acquire-16-players",
    img: images.press03.image,
    heroImg: images.pressMb03.image,
    alt: images.press03.alt,
    title: "February 26, 2024",
    desc: "Chennai Singams Acquire 16 Players",
    innerTitle: "Chennai Singams Acquire 16 Players for Rs 96.4 Lakhs at the inaugural edition of Indian Street Premier League",
    innerContent: [
      `Business stalwarts Rajdip Gupta, Sandip Gupta, and actor Suriya Sivakumar, promoters of Chennai Singams bought 16 players, including star player Sumeet Dhekale aka ‘Bahubali’ for the inaugural edition of ISPL. The Chennai-based franchise is among the six teams competing in the street-to-stadium league's premiere edition from 6th to 15th March 2024 in Mumbai.`,
      `The Chennai Singams team, with a purse of ₹1 Crore, bought 16 players for a cumulative amount of ₹96.4 Lakhs, making the team the highest bidder at the auction. Representing Chennai Singams were co-owners, Rajdip Gupta, and the core team consisting Rahul Pandey, Ashish Jaiswal and Sandeep Jaiswal.`,
      `Entrepreneur Rajdip Gupta, Co-owner, Chennai Singams said, “Our team strength is our players. We are elated to have bought some of the best players in the lot. The scouting camp that we hosted was very fruitful. Can’t wait for the Chennai Singams team to take to the field.”`,
      `Actor Suriya Sivakumar, Co-Owner, Chennai Singams said, “Witnessing the dedication, skill and talent on display, namely Sumeet Dhekale, Ketan Mhatre, R. Thavith Kumar, Venkatachalapathi Vignesh among others, I am confident that Chennai Singams will cross a milestone in the upcoming inaugural edition of the Indian Street Premier League. Our team is marked by passion, determination, and a relentless pursuit of excellence. Experience and raw talent are hard to resist! Chennai Singams is more than just a team; cricket is more than just a game for us. It’s an emotion! Like all cricket lovers, I also eagerly await the game of our players in the ISPL matches. Sports and sportsmanship are a forever inspiration! Let us roar together, Chennai Singams!"`,
      `A team formed with players from different corners of the country, Chennai Singams, bought Sumeet Dhekale for ₹19 Lakhs who is one of the most expensive players followed by Ketan Mhatre for ₹16.5, Farman Khan for ₹10.5 Lakhs, Dilip Binjwa for 9 Lakhs, Sanjay Kanojjiya for ₹7 Lakhs, Sagar Ali for ₹4.4 Lakhs, Harish Parmar for ₹3 Lakhs, Vedant Mayekar for ₹3 Lakhs, Pankaj Patel for ₹3 Lakhs, Farhat Ahmad for ₹3 Lakhs, R. Thavith Kumar for ₹3 Lakhs, Venkatachalapathi Vignesh for ₹3 Lakhs, Aniket Sanap for ₹3 Lakhs, Bablu Patil for ₹3 Lakhs, Rajdeep Jadeja for ₹3 Lakhs and Vishwanath Jadhav for ₹3 Lakhs.`,
      `The strength of Chennai Singams comes together with 6 players from the West zone, and two each from the East, North, South, Central and under 19 categories.`,
      `Zone Wise Break – Up`,
      `East Zone`,
      `<ul>
      <li>Sagar Ali – Left Handed– Batsman</li>
      <li>Sanjay Kanojjiya – Left Handed – Batsman – Right Arm spinner</li>
      </ul>`,
      `North Zone`,
      `<ul>
      <li>Farman Khan – Right-Handed – Bowler – Right Arm Fast</li>
      <li>Farhat Ahmad – Right-Handed – Bowler – Right Arm Fast</li>
      </ul>`,
      `South Zone`,
      `<ul>
      <li>R. Thavith Kumar – All rounder– Left-Handed – Left Arm Fast</li>
      <li>Venkatachalapathi Vignesh - All rounder– Right-Handed – Right Arm Medium</li>
      </ul>`,
      `Central Zone`,
      `<ul>
      <li>Dilip Binjwa – All rounder– Left-Handed – Left Arm Medium</li>
      <li>Pankaj Patel – Bowler – Right Handed – Left Arm Fast</li>
      </ul>`,
      `West Zone`,
      `<ul>
      <li>Sumeet Dhekale – Batsman – Left Handed</li>
      <li>Bablu Patil – All rounder</li>
      <li>Ketan Mhatre – Batsman – Right Handed – Right Arm Fast</li>
      <li>Aniket Sanap – Bowler – Left Handed – Left Arm Spinner</li>
      <li>Rajdeep Jadeja – Bowler – Right Handed – Right Arm Spinner</li>
      <li>Vishwanath Jadhav – All rounder– Right Handed - Right Arm Fast</li>
      <li></li>
      </ul>`,
      `In the under 19 category, Vedant Mayekar (18-year-old) and Harish Parmar (19-year-old), both from west zone were added to the Chennai Singams squad.`,
      `<ul>
      <li>Vedant Mayekar - Right Handed – All rounder – Right Arm Fast</li>
      <li>Harish Parmar - Right Handed – All rounder – Right Arm Fast</li>
      </ul>`,
      `The six teams who are part of ISPL - are Mumbai, Kolkata, Bengaluru, Hyderabad, Srinagar and Chennai.`,
      `About Chennai Singams`,
      `Chennai Singams, the Chennai-based Indian Street Premier League (ISPL) franchise, is co-owned by Rajdip Gupta, Sandip Gupta, and actor Suriya Sivakumar. The team has a roaring lion as their logo, which represents Chennai's strength and valour, while the vibrant yellow colour enhances the city's radiance. The owners are committed to sports promotion and talent development, as evidenced by their ownership of multiple clubs and support for women's cricket. As the ISPL prepares for its thrilling T10 tennis ball cricket tournament from March 6th to 15th, 2024, Chennai Singams promises to captivate audiences with their spirited performances on Mumbai's streets-turned-stadiums.`,
      `Check out our in-depth <a href="https://www.cnbctv18.com/market/" target="_blank">Market Coverage</a>, <a href="https://www.cnbctv18.com/business/" target="_blank">Business News</a> & get real-time <a href="https://www.cnbctv18.com/market-live/" target="_blank">Stock Market</a> Updates on CNBC-TV18. Also, Watch our channels <a href="https://www.cnbctv18.com/live-tv/" target="_blank">CNBC-TV18</a>, <a href="https://www.cnbctv18.com/awaaz/live-tv/" target="_blank">CNBC Awaaz</a> and <a href="https://www.cnbctv18.com/bajar/live-tv/" target="_blank">CNBC Bajar</a> Live on-the-go!`,
    ],
  },
  {
    id: 5,
    slug: "chennai-singams-brand-identity",
    img: images.press04.image,
    heroImg: images.pressMb04.image,
    alt: images.press04.alt,
    title: "February 14, 2024",
    desc: "Chennai Singams Brand Identity",
    innerTitle: "Indian Street Premier League’s Chennai Singams Unveils its Brand Identity Actor Suriya Sivakumar’s Chennai Singams Unveils its Brand Identity",
    innerContent: [
      `The Indian Street Premier League (ISPL) franchise Chennai Singams unveiled their logo for the highly anticipated inaugural edition of the league. Rajdip Gupta, Sandip Gupta- promoters of Route Mobile Ltd and Actor Suriya Sivakumar are the co-owners of Chennai Singams, a Chennai-based franchise.`,
      `The logo with a roaring lion depicts the great strength and valour of Chennai. The standout logo perfectly aligns with ISPL’s mission to merge the spirit of the streets with the grandeur of stadium cricket. Additionally, the highlighted yellow colour in the logo visually depicts the radiance of Chennai.`,
      `<span>Rajdip Gupta, Co-Owner of Chennai Singams</span> said “Both I and Sandip besides being entrepreneurs are very passionate towards sports and the same reflects in our ownership of multiple clubs in MCA that have produced talented players like Sudhir Naik, Zaheer Khan, Wasim Jaffer, Rajesh Pawar, Raju Sutar, Nilesh Kulkarni besides our ownership of North Mumbai Panthers that plays at the Mumbai T20 Premiere League and Saraswati Sports Complex in Mumbai that provides good sporting amenities.`,
      `Additionally, he stated, “Women’s Cricket is another focus area we would like to develop and currently we have set up a team via National Cricket Club from which 5 women and 5 men are part of the Mumbai State team in BCCI”`,
      `ISPL is scheduled to kick off from March 6th to March 15th 2024, in Mumbai, The Indian Street Premier League (ISPL) is India’s first-ever T10 tennis ball cricket tournament. Promising an enthralling display of cricketing brilliance, the tournament will feature 19 high-intensity matches, boasting a line-up of six teams – Hyderabad, Mumbai, Bengaluru, Chennai, Kolkata, and Srinagar.`,
    ],
  },
  {
    id: 6,
    slug: "virar-hub-incubator",
    img: images.press06.image,
    heroImg: images.pressMb06.image,
    alt: images.press06.alt,
    title: "October 12, 2023",
    desc: "Virar Hub incubator",
    innerTitle: "Contentstack's Nishant Patel Kickstarts Virar-Hub Incubator for Local Entrepreneurs",
    innerContent: [
      `In Virar, Maharashtra, a region bursting with untapped potential, a new dawn emerges for young startups and aspirants. Spearheaded by Nishant Patel, the Co-founder of Contentstack , the Virar-Hub Incubator has been unveiled, ready to propel Virar onto the entrepreneurial world stage.`,
      `Drawing its essence from the idea of nurturing innovation in regions often overlooked, this incubator stands as a beacon for early-stage startups. Beyond just being a space, the Virar-Hub Incubator pledges to provide startups with the invaluable mentorship, resources, and strategic collaborations they require to metamorphose into successful enterprises.`,
      `Nestled in the heart of the Vasai-Virar area, the incubator's primary objective transcends its physical location. It is envisioned as a groundbreaking platform, cultivating an entrepreneurial ecosystem and mindset in an area ripe for such a transformation.`,
      `Lending their expertise and unwavering support to Nishant's mission are notable figures like Rajdip Gupta, Founder and CEO of Route Mobile Ltd., and Kshitij Thakur, a representative of the Nalasopara constituency in the Maharashtra State Assembly. Their association not only adds gravitas to the initiative but also underlines the unified commitment to see Virar flourish.`,
      `Recalling his entrepreneurial journey that took root in Virar, Nishant expressed his excitement, stating, "Witnessing Virar's untapped potential first-hand, I'm invigorated to initiate the Virar-Hub Incubator. The aspiration is to sow the seeds for the next-gen startups here." This sentiment was further echoed with the launch of the Raw Engineering Academy, dedicated to equipping students with pertinent tech and entrepreneurship skills.`,
      `A standout event that solidified the incubator's ethos was the Virar-Hub StartUp Quest Competition. With a call to students across Greater Mumbai, the competition became a melting pot of innovation, with 475 enthusiastic participants and over 200 teams. From these, ten elite teams battled it out in the final round, with three outstanding teams now on the cusp of getting incubated within the Virar-Hub.`,
      `The Virar-Hub Incubator isn't just a facility; it's a vision. A vision of transforming Virar into an entrepreneurial stronghold and nurturing the innovative ideas germinating in its soil. It's the dawn of a new era for Virar, and the future seems promising.`,
    ],
  },
  {
    id: 7,
    slug: "et-ascent-business-leaders",
    img: images.press07.image,
    heroImg: images.pressMb07.image,
    alt: images.press07.alt,
    title: "March 1, 2023",
    desc: "ET Ascent Business Leaders",
    innerTitle: "Route Mobile Limited Wins Four ET ascent Awards for Constant Innovation  in the CPaaS Segment",
    innerContent: [
      `<span>Rajdip Gupta Wins “CEO of the Year” and “Cloud Innovator of the Year” Award</span>`,
      `<span>Mumbai, March 1, 2023: </span>Route Mobile Limited (“RML/Route Mobile”) (BSE:543228, NSE: ROUTE), wins two awards; “Best Use of Cloud Services by a Telecom Company” & “Best Enterprise Cloud Offering” (IT Sector) at the ET ascent Business Leader of the Year Awards 2023. Along with these, Mr. Rajdip Gupta was conferred with “CEO of the Year” & “Cloud Innovator of the Year” awards. Several great leaders are honoured every year by ET ascent and World HRD Congress for their accomplishments and contributions towards the advancement of their organisations and industries.`,
      `On receiving these awards, Mr. Rajdip Gupta, Managing Director & Group CEO, Route Mobile said, “I am deeply honoured and humbled to receive these prestigious awards from the premier industry organization ET ascent and thank The Jury council of World HRD Congress & Awards. Route Mobile is building new-age technologies and solutions, which strive to simplify the lives of millions through easy-to- adopt and secured technology. Awards like these not just encourages us but also endorses that we are on the right path.”`,
      `Thanking his team, Mr. Rajdip said, “These awards are a result of passion and dedication of each team member at Route Mobile, the true custodians of these awards. I am proud to have a fantastic R&D team that contributes to innovation and ingenuity every day and whose entrepreneurial mindset is evident. With this recognition, we solidify our global position as innovators and disruptors, and we are committed to investing in powerful solutions for our customers.”`,
      `Talking about the sector, Mr. Rajdip said, “Route Mobile is one of the fastest-growing CPaaS leaders globally. Every industry and every organisation will have to transform itself in the next few years and our mission is to revolutionise the omnichannel communication space and become a trusted partner for enterprises in their accelerated digital adoption journey.”`,
      `The ET ascent Business Leader of the Year Awards 2023 honours business executives and organisations that have made significant contributions to the business sector. The recognition acknowledges noteworthy professional achievements and acts as a solid third-party endorsement for a brand’s products, services and accomplishments, increasing their worth. Members of the Committee perform
      research based on ongoing content analysis and monitoring of the top business websites, standalone publications, and business press.`,
      `<span>About Route Mobile Limited</span> <a href="www.routemobile.com" target="_blank">(www.routemobile.com)</a> <span>(BSE: 543228; NSE: ROUTE)</span>`,
      `Established in 2004, Route Mobile Limited (“RML”) is a cloud communications platform service provider, catering to enterprises, over-the-top (OTT) players and mobile network operators (MNO). RML’s portfolio comprises solutions in messaging, voice, email, SMS filtering, analytics and monetization. RML has a diverse enterprise client base across a broad range of industries including social media companies, banks and financial institutions, e-commerce entities and travel aggregators. RML is headquartered in Mumbai, India with a global presence in Asia Pacific, the Middle East, Africa, Europe and the Americas.`,
      `Additional Resources`,
      `<ul>
      <li>Follow Route Mobile on Linkedin: <a href="" target="_blank">https://in.linkedin.com/company/routemobilelimited</a></li>
      <li>Follow Route Mobile on Twitter: <a href="" target="_blank">https://twitter.com/route_mobile</a></li>
      <li>Become a fan of Route Mobile: <a href="" target="_blank">https://www.facebook.com/Routemobilelimited</a></li>
      </ul>`,
      `Media Contact:`,
      `Mr. Milind Pathak <br/> Email: <a href="mailto:press@routemobile.com">press@routemobile.com</a> <br/> <span>Contact:</span> <a href="tel:+9102240337676">+91 (022) 40337676<a>`,
      `Ms Isha Pawar <br/> Email: <a href="mailto:sha@conceptpr.com">sha@conceptpr.com</a> <br/> <span>Contact:</span> <a href="tel:+917738344952">+91 77383 44952<a>`,
    ],
    // innerLink: press07Pdf,
  },
];
