import React, { useEffect, useState } from "react";
import CommonBanner from "../../components/commonBanner/CommonBanner";
import ExpandableCardGrid from "../../components/expandableCard/ExpandableCardGrid";
import ValuesCard from "../../components/valueCard/ValuesCard";
import { ourAchievements, people, sections } from "../../data/ourCompany";
import "./ourCompany.scss";
import SectionContent from "../../components/SectionContent/SectionContent";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { ctaBlackArrow } from "../../images";
import { useWindowSize } from "react-use";
import http from "../../helpers/http";
import config from "../../config/config";

const OurCompany = () => {
  const { width } = useWindowSize();
  const base_url = config.api_url;
  const image_url = config.image_url;
  const [bannerData, setBannerData] = useState({
    mobile_banner: "",
    desktop_banner: "",
  });

  const [achievements, setAchievements] = useState([]);
  const [leadershipTeam, setLeadershipTeam] = useState([]);
  const [section1, setSection1] = useState([]);
  const [pageContent, setPageContent] = useState({});

  const fetchBanner = async () => {
    const { data } = await http.get(`${base_url}/section-banners`);
    const ourCompanyBanner = data.find(
      (banner) => banner.section === "our-company"
    );

    if (ourCompanyBanner) {
      setBannerData({
        mobile_banner: ourCompanyBanner.mobile_banner,
        desktop_banner: ourCompanyBanner.desktop_banner,
      });
    }
  };

  const fetchAcheivements = async () => {
    const { data } = await http.get(`${base_url}/our-company/achievements`);
    if (data) {
      setAchievements(
        data.map((it) => ({
          img: `${image_url}${it.image.url}`,
          alt: it.image.name,
          title: "Trailblazer CEO Award",
          description: it.content,
        }))
      );
    }
  };

  const fetchLeadershipTeams = async () => {
    const { data } = await http.get(`${base_url}/our-company/leadership`);
    if (data) {
      setLeadershipTeam(
        data.map((it) => ({
          name: it.name,
          title: it.role,
          imageUrl: `${image_url}${it?.image?.url}`,
          description: it.info,
        }))
      );
    }
  };

  const fetchSection1Content = async () => {
    const { data } = await http.get(`${base_url}/our-company/section1`);
    if (data) {
      setSection1(
        data.map((it) => ({
          title: it.title,
          content: it.description,
          icon: `${image_url}${it?.icon?.url}`,
        }))
      );
    }
  };

  const fetchMainContent = async () => {
    const { data } = await http.get(`${base_url}/our-company/content`);
    if (data) {
      setPageContent(data[0]);
    }
  };

  useEffect(() => {
    fetchBanner();
    fetchAcheivements();
    fetchLeadershipTeams();
    fetchSection1Content();
    fetchMainContent();
  }, []);

  const ourAchievementsList = achievements.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="news_box">
        <div className="img_wrapper">
          <img src={item.img} alt={item.alt} className="news_img" />
        </div>
        <div className="news_content">
          <p
            className="section_desc20"
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </div>
      </div>
    </SwiperSlide>
  ));

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <CommonBanner
        title={"Our Company"}
        banner={
          width > 600
            ? `${image_url}${bannerData?.desktop_banner?.url}`
            : `${image_url}${bannerData?.mobile_banner?.url}`
        }
        link1={"home"}
        linkText1={"about"}
        linkText2={"our company"}
      />

      <div className="our-company">
        <SectionContent
          leftHeading={pageContent.main_content_heading}
          desc={pageContent?.main_content_description}
        />

        <section className="company_sec3 my_container">
          <ValuesCard sections={section1} />
        </section>

        {/* <section className="company_sec4 my_container">
          <CustomSlider slides={achievements} slidesPerView={3} />
        </section> */}

        <section className="company_slider_sec">
          <div className="my_container">
            <div className="head_wrapper">
              <h2 className="section_heading32">
                {pageContent.achievements_heading}
              </h2>
              {width > 767 && (
                <div className="company_arrows">
                  <div className="arrow_wrapper_left">
                    <img
                      src={ctaBlackArrow}
                      alt="left arrow"
                      className="left_arrow"
                    />
                  </div>
                  <div className="arrow_wrapper_right">
                    <img
                      src={ctaBlackArrow}
                      alt="right arrow"
                      className="right_arrow"
                    />
                  </div>
                </div>
              )}
            </div>
            <Swiper
              slidesPerView={3}
              spaceBetween={16}
              grabCursor={true}
              loop={true}
              modules={[Navigation, Autoplay]}
              navigation={{
                nextEl: ".arrow_wrapper_right",
                prevEl: ".arrow_wrapper_left",
              }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 16,
                },
              }}
              className="company_slider"
            >
              {ourAchievementsList}
              {width <= 767 && (
                <div className="company_arrows">
                  <div className="arrow_wrapper_left">
                    <img
                      src={ctaBlackArrow}
                      alt="left arrow"
                      className="left_arrow"
                    />
                  </div>
                  <div className="arrow_wrapper_right">
                    <img
                      src={ctaBlackArrow}
                      alt="right arrow"
                      className="right_arrow"
                    />
                  </div>
                </div>
              )}
            </Swiper>
          </div>
        </section>

        <section className="card-section">
          <SectionContent
            leftHeading={pageContent.leadership_heading}
            desc={pageContent.leadership_content}
          />
          <div className="my_container">
            <ExpandableCardGrid people={leadershipTeam} />
          </div>
        </section>
      </div>
    </>
  );
};

export default OurCompany;
