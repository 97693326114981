// routes name
export const homeUrl = "/";
export const homeUrlDynamic = "/dynamic";
export const ourCompanyUrl = "/our-company";
export const ourCompanyDynamicUrl = "/our-company-dynamic";
export const teamManagementUrl = "/management-team";
export const teamManagementDynamicUrl = "/management-team-dynamic";
export const galleryUrl = "/gallery";
export const galleryDynamicUrl = "/gallery/dynamic";
export const sportsUrl = "/sports";
export const investmentUrl = "/investments";
export const contactusUrl = "/contact-us";
export const contactusDynamicUrl = "/contact-us-dynamic";
export const careersUrl = "/careers";
export const careersDynamicUrl = "/careers-dynamic";
export const csrinitiativesUrl = "/csr-initiatives";
export const newsCenterUrl = "/news-centre";
export const newsCenterDetailsUrl = "/news-centre/:slug";
// export const newsCenterDynamicUrl = "/news-center-dynamic";
// export const newsCenterDetailsDynamicUrl = "/news-center-dynamic/:id";
export const hospitalityUrl = "/hospitality";
export const hospitalityDynamicUrl = "/hospitality-dynamic";
export const termsUrl = "/terms-and-conditions";
export const privacyyUrl = "/privacy-policy";
export const healthcareUrl = "/healthcare";
export const renewableEnergyUrl = "/renewable-energy";
export const infrastructureUrl = "/infrastructure";
export const informationTechnologyUrl = "/information-technology";
export const businessVentureDynamicUrl = "/business-venture-dynamic/:id";
