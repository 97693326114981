import React, { useEffect, useState } from "react";
import "./BusinessVenturePage.scss";
import SectionContent from "../../components/SectionContent/SectionContent";
import CommonBanner from "../../components/commonBanner/CommonBanner";
import { useWindowSize } from "react-use";
import VentureBox from "../../components/VentureBox/VentureBox";
import { hospitalityBanner, hospitalityBannerMb } from "../../images";
import { useLocation } from "react-router-dom";
import http from "../../helpers/http";
import config from "../../config/config";
import { useParams } from "react-router-dom";

const BusinessVentureDynamic = () => {
  const { width } = useWindowSize();
  const location = useLocation();
  const base_url = config.api_url;
  const { id } = useParams();
  const [ventureData, setVentureData] = useState(null);

  const fetchCategoryData = async () => {
    const { data } = await http.get(
      `${base_url}/business-ventures/category-by-slug/${id}`
    );
    if (data) {
      setVentureData(data);
    }
  };

  const TeamCard = ({ box_image, box_title, box_description }) => (
    <div className="team-card">
      <img
        src={`${base_url}/public/uploads/${box_image?.url}`}
        alt={`${box_image.name}`}
        className="team-logo"
      />
      <div className="team-info">
        <h3 className="team-name">{box_title}</h3>
        <p
          className="team-description"
          dangerouslySetInnerHTML={{ __html: box_description }}
        ></p>
      </div>
    </div>
  );

  useEffect(() => {
    fetchCategoryData();
  }, [id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const renderSection = (section, index) => {
    switch (section.layout_type) {
      case "title_desc":
        return (
          <section key={index} className="hosp_sec1">
            {/* {section.section_title && <h2>{section.section_title}</h2>} */}
            <SectionContent
              leftHeading={section.section_title}
              desc={section.section_description}
            />
            {section.additional_content && (
              <div
                dangerouslySetInnerHTML={{ __html: section.additional_content }}
              />
            )}
          </section>
        );
      case "title_desc_image_horizontal":
        return (
          <section key={index} className="hosp_sec2">
            <VentureBox
              leftHeading={section.section_title}
              VentureBoxData={section.box_sections.map((box, idx) => ({
                id: idx + 1,
                img: `${base_url}/public/uploads/${box.box_image?.url}`,
                alt: box.box_title,
                title: box.box_title,
                desc: box.box_description,
              }))}
            />
            {section.additional_content && (
              <div
                dangerouslySetInnerHTML={{ __html: section.additional_content }}
              />
            )}
          </section>
        );
      case "title_desc_image_vertical":
        return (
          <div className="sports-venture">
            <section key={index} className={`teams-container`}>
              {/* {section.section_title && <h2>{section.section_title}</h2>} */}
              {section.box_sections?.map((box, idx) => (
                <TeamCard key={idx} {...box} />
              ))}
              {section.additional_content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: section.additional_content,
                  }}
                />
              )}
            </section>
          </div>
        );
      default:
        return null;
    }
  };

  if (!ventureData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <CommonBanner
        title={ventureData.title}
        banner={width > 767 ? hospitalityBanner : hospitalityBannerMb}
        linkText1="Business Ventures"
        linkText2={ventureData.title}
      />

      {ventureData.sections.map((section, index) =>
        renderSection(section, index)
      )}
    </>
  );
};

export default BusinessVentureDynamic;
