import React, { useEffect } from "react";
import CommonBanner from "../../components/commonBanner/CommonBanner";
import ExpandableCardGrid from "../../components/expandableCard/ExpandableCardGrid";
import ValuesCard from "../../components/valueCard/ValuesCard";
import { ourAchievements, people, sections } from "../../data/ourCompany";
import "./ourCompany.scss";
import SectionContent from "../../components/SectionContent/SectionContent";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { ctaBlackArrow } from "../../images";
import { useWindowSize } from "react-use";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const OurCompany = () => {
  const { width } = useWindowSize();

  const ourAchievementsList = ourAchievements.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="news_box">
        <div className="img_wrapper">
          <img src={item.img} alt={item.alt} className="news_img" />
        </div>
        <div className="news_content">
          <p
            className="section_desc20"
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </div>
      </div>
    </SwiperSlide>
  ));

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <MetaDecorator
        metaTitle="Our Company - SanRaj"
        metaDesc="Learn about SanRaj's vision and mission. Discover our commitment to excellence and innovation across various sectors, driving sustainable growth and impact."
        canonicalLink={window.location}
      />

      <CommonBanner
        title={"Our Company"}
        banner={
          width > 600
            ? "/icons/our-company/our-company-banner.jpg"
            : "/icons/our-company/our-company-banner-mb.jpg"
        }
        linkText1={"About"}
        linkText2={"Our Company"}
      />

      <div className="our-company">
        <SectionContent
          leftHeading="ABOUT <br /> SANRAJ GROUP"
          desc={
          `SanRaj Family Ventures Pvt. Ltd., founded by Sandip Gupta and Rajdip Gupta, is a story of dedication and vision brought to life over two decades of hard work, resilience, and shared values. Guided by their deep understanding of diverse industries and their hands-on approach, they have built a legacy of growth, innovation, and trust. Since its inception in 2009, SanRaj Group has made strides across various sectors—hospitality, information technology, healthcare, sports, renewable energy, and infrastructure. Each venture has been driven by a passion for excellence and a steadfast commitment to adding long-term value to the industries and communities they touch.
           <br/>
           For SanRaj Group, it’s not just about business; it’s about creating a meaningful impact and fostering deep, lasting relationships with our stakeholders. Our journey is rooted in respect, trust, and a shared commitment to building a sustainable future together. As we continue to grow, we stay guided by the principles that every stakeholder is part of our extended family. Every decision is made with a sense of responsibility and purpose, knowing that our success is intertwined with the dreams and goals of those we work with. Our promise is simple: to deliver excellence while nurturing connections that go beyond transactions, cultivating a shared journey built on trust, loyalty, and a common vision for a better tomorrow.
          `}
        />

        <section className="company_sec3 my_container">
          <ValuesCard sections={sections} />
        </section>

        {/* <section className="company_sec4 my_container">
          <CustomSlider slides={achievements} slidesPerView={3} />
        </section> */}

        <section className="company_slider_sec">
          <div className="my_container">
            <div className="head_wrapper">
              <h2 className="section_heading32">OUR ACHIEVEMENTS</h2>
              {width > 767 && (
                <div className="company_arrows">
                  <div className="arrow_wrapper_left">
                    <img
                      src={ctaBlackArrow}
                      alt="left arrow"
                      className="left_arrow"
                    />
                  </div>
                  <div className="arrow_wrapper_right">
                    <img
                      src={ctaBlackArrow}
                      alt="right arrow"
                      className="right_arrow"
                    />
                  </div>
                </div>
              )}
            </div>
            <Swiper
              slidesPerView={3}
              spaceBetween={16}
              grabCursor={true}
              loop={true}
              modules={[Navigation, Autoplay]}
              navigation={{
                nextEl: ".arrow_wrapper_right",
                prevEl: ".arrow_wrapper_left",
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 16,
                },
              }}
              className="company_slider"
            >
              {ourAchievementsList}
              {width <= 767 && (
                <div className="company_arrows">
                  <div className="arrow_wrapper_left">
                    <img
                      src={ctaBlackArrow}
                      alt="left arrow"
                      className="left_arrow"
                    />
                  </div>
                  <div className="arrow_wrapper_right">
                    <img
                      src={ctaBlackArrow}
                      alt="right arrow"
                      className="right_arrow"
                    />
                  </div>
                </div>
              )}
            </Swiper>
          </div>
        </section>

        <section className="card-section">
          <SectionContent
            leftHeading={"LEADERSHIP TEAM"}
            desc={
              "Our leadership team is comprised of seasoned industry professionals who bring a wealth of experience and strategic vision, driving innovation and growth across our diverse business sectors."
            }
          />
          <div className="my_container">
            <ExpandableCardGrid people={people} />
          </div>
        </section>
      </div>
    </>
  );
};

export default OurCompany;
