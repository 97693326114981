import React, { useEffect, useState } from "react";
import CommonBanner from "../../components/commonBanner/CommonBanner";
import GalleryGrid from "../../components/gallery/GalleryGrid";
import { galleryImage } from "../../data/gallery";
import "./gallery.scss";
import { useWindowSize } from "react-use";
import { images } from "../../images";
import config from "../../config/config";
import http from "../../helpers/http";
import GalleryGridDynamic from "../../components/gallery/GalleryGridDynamic";

const GalleryDynamic = () => {
  const { width } = useWindowSize();
  const [galleryImages, setGalleryImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sectionBanners, setSectionBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const base_url = config.api_url;
  const image_url = config.image_url;

  const fetchData = async () => {
    const [imagesResponse, categoriesResponse, sectionBanner] =
      await Promise.all([
        http.get(`${base_url}/gallery`),
        http.get(`${base_url}/gallery/categories`),
        http.get(`${base_url}/section-banners`),
      ]);

    if (
      imagesResponse?.data &&
      categoriesResponse?.data &&
      sectionBanner?.data
    ) {
      setSectionBanners(
        sectionBanner.data.find((it) => it.section === "gallery")
      );
      setGalleryImages(imagesResponse?.data);
      setCategories(categoriesResponse?.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <CommonBanner
        title={"Gallery"}
        banner={
          width > 600
            ? `${image_url}${sectionBanners?.desktop_banner?.url}`
            : `${image_url}${sectionBanners?.mobile_banner?.url}`
        }
        linkText1={"Gallery"}
      />

      <section className="gallery">
        {categories.length > 0 && galleryImages.length > 0 && (
          <GalleryGridDynamic images={galleryImages} categories={categories} />
        )}
      </section>
    </div>
  );
};

export default GalleryDynamic;
