import React from "react";
import "./galleryCard.scss";
import config from "../../config/config";

const GalleryCardDynamic = ({ image }) => {
  const image_url = config.image_url;
  console.log(image);

  return (
    <div className="category-card">
      <img
        src={`${image_url}${image?.image?.url}`}
        alt={image?.alt_tag}
        className="category-card__image"
      />
    </div>
  );
};

export default GalleryCardDynamic;
