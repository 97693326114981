import React, { useEffect, useState } from "react";
import CommonBanner from "../../components/commonBanner/CommonBanner";
import ExpandableCardGrid from "../../components/expandableCard/ExpandableCardGrid";
import SectionContent from "../../components/SectionContent/SectionContent";
import { team } from "../../data/managementTeam";
import "./managementTeam.scss";
import { useWindowSize } from "react-use";
import config from "../../config/config";
import http from "../../helpers/http";

const ManagementTeamDynamic = () => {
  const { width } = useWindowSize();
  const base_url = config.api_url;
  const image_url = config.image_url;
  const [bannerData, setBannerData] = useState({
    mobile_banner: "",
    desktop_banner: "",
  });
  const [managementTeam, setManagementTeam] = useState([]);
  const [pageContent, setPageContent] = useState({});

  const fetchBanner = async () => {
    const { data } = await http.get(`${base_url}/section-banners`);
    const managementTeamBanner = data.find(
      (banner) => banner.section === "management-team"
    );

    if (managementTeamBanner) {
      setBannerData({
        mobile_banner: managementTeamBanner.mobile_banner,
        desktop_banner: managementTeamBanner.desktop_banner,
      });
    }
  };

  const fetchTeams = async () => {
    const { data } = await http.get(`${base_url}/management-team`);
    const content = await http.get(`${base_url}/management-team/content`);
    if (data && content?.data) {
      setManagementTeam(
        data.map((it) => ({
          name: it?.name,
          title: `${it?.role}, ${it?.company}`,
          imageUrl: `${image_url}${it?.image?.url}`,
          description: `${it?.info}`,
        }))
      );
      setPageContent(content.data);
    }
  };

  useEffect(() => {
    fetchBanner();
    fetchTeams();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <CommonBanner
        title={"Management Team"}
        banner={
          width > 600
            ? `${image_url}${bannerData?.desktop_banner?.url}`
            : `${image_url}${bannerData?.mobile_banner?.url}`
        }
        link1={"home"}
        linkText1={"about"}
        linkText2={"our company"}
      />

      <div className="management-team">
        <SectionContent
          leftHeading={pageContent.title}
          desc={pageContent?.description}
        />
        <section className="team_sec3 my_container">
          <ExpandableCardGrid people={managementTeam} />
        </section>
      </div>
    </>
  );
};

export default ManagementTeamDynamic;
