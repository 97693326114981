import React, { useEffect, useState } from "react";
import "./hospitality.scss";
import SectionContent from "../../components/SectionContent/SectionContent";
import CommonBanner from "../../components/commonBanner/CommonBanner";
import { useWindowSize } from "react-use";
import VentureBox from "../../components/VentureBox/VentureBox";
import { hospitalityBanner, hospitalityBannerMb } from "../../images";
import http from "../../helpers/http";
import config from "../../config/config";

const HospitalityDynamic = () => {
  const { width } = useWindowSize();
  const [hospitalityData, setHospitalityData] = useState(null);
  const base_url = config.api_url;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    fetchHospitalityData();
  }, []);

  const fetchHospitalityData = async () => {
    const { data } = await http.get(
      `${base_url}/business-ventures/hospitality`
    );
    console.log(data);

    setHospitalityData(data);
  };

  if (!hospitalityData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <CommonBanner
        // title={hospitalityData.banner.title}
        // banner={
        //   width > 767
        //     ? hospitalityData.banner.desktop_image
        //     : hospitalityData.banner.mobile_image
        // }
        // linkText1={hospitalityData.banner.breadcrumb1}
        // linkText2={hospitalityData.banner.breadcrumb2}
        title={"Hospitality"}
        banner={width > 767 ? hospitalityBanner : hospitalityBannerMb}
        linkText1={"Business Ventures"}
        linkText2={"Hospitality"}
      />

      <section className="hosp_sec1">
        <SectionContent
          leftHeading={hospitalityData.section}
          desc={hospitalityData.description}
        />
      </section>
      <section className="hosp_sec2">
        <VentureBox
          leftHeading={hospitalityData.section2.heading}
          VentureBoxData={hospitalityData.section2.properties}
        />
      </section>
    </>
  );
};

export default HospitalityDynamic;
