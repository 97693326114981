import React, { useState } from "react";
import "./galleryCard.scss";
import GalleryCard from "./GalleryCard";
import { useWindowSize } from "react-use";
import GalleryCardDynamic from "./GalleryCardDynamic";

// const categories = [
//   { name: "Infrastructure", key: "infrastructure" },
//   { name: "Hospitality", key: "hospitality" },
//   { name: "Healthcare", key: "healthcare" },
//   { name: "Renewable Energy", key: "renewableEnergy" },
//   { name: "Sports", key: "sports" },
//   { name: "IT", key: "it" },
// ];

const GalleryGridDynamic = ({ images, categories }) => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]?._id);
  const { width } = useWindowSize();

  const filteredImages = selectedCategory
    ? images.filter((image) => image.category._id === selectedCategory)
    : images;

  const handleProductTabChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);
  };

  return (
    <section className="category-section my_container">
      {width > 992 ? (
        <div className="category-filters">
          {categories.map((category) => (
            <button
              key={category._id}
              className={`category-button ${
                selectedCategory === category._id ? "active" : ""
              }`}
              onClick={() => setSelectedCategory(category._id)}
            >
              {category.name}
            </button>
          ))}
        </div>
      ) : (
        <div className="category-filters">
          <select
            className={`category-button`}
            onChange={handleProductTabChange}
            value={selectedCategory}
          >
            {categories.map((category) => (
              <option value={category._id} key={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="category-images">
        {filteredImages.map((image, index) => (
          <GalleryCardDynamic key={image._id} image={image} />
        ))}
      </div>
    </section>
  );
};

export default GalleryGridDynamic;
