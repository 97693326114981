import React, { useEffect } from "react";
import "./sports.scss";
import CommonBanner from "../../components/commonBanner/CommonBanner";
import { sections } from "../../data/sports";
import { sportsBanner, sportsBannerMb } from "../../images";
import { useWindowSize } from "react-use";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const SectionCard = ({
  title,
  content,
  isTeam = false,
  children,
  sectionImg,
  cardImg,
  logos,
}) => (
  <div className={`section-card ${isTeam ? "team-section" : ""}`}>
    <h2 className="section-title">{title}</h2>
    <div className="section-content">
      {sectionImg && (
        <img src={sectionImg} alt="saraswati devi" className="sec_img" />
      )}
      {isTeam ? children : <p>{content}</p>}

      {cardImg && (
        <div className="row card_img_row">
          {cardImg.map((item, i) => (
            <div className="col-md-6 card_img_col">
              <div className="img_wrapper">
                <img
                  src={item}
                  alt="saraswati devi"
                  className="card_img"
                  key={i}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {logos && (
        <div className="row log_row">
          {logos.map((logo, i) => (
            <div className="col-lg-4 col-md-6 col-6 logo_col">
              <div className="logo_wrapper">
                <img
                  src={logo}
                  alt="saraswati devi"
                  className="card_img"
                  key={i}
                />
              </div>
            </div>
          ))}
        </div>
      )}

    </div>
  </div>
);

const TeamCard = ({ logo, name, description }) => (
  <div className="team-card">
    <img src={logo} alt={`${name} logo`} className="team-logo" />
    <div className="team-info">
      <h3 className="team-name">{name}</h3>
      <p
        className="team-description"
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
    </div>
  </div>
);

const SportsVenture = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <MetaDecorator
        metaTitle="Premier Cricket Franchises & Sports Club Owners | Cricket Clubs in Mumbai - SanRaj"
        metaDesc="SanRaj specializes in the ownership of premier cricket franchises and elite sports clubs, offering top sports turf and grounds for sports training in Mumbai."
        canonicalLink={window.location}
      />
      
      <CommonBanner
        title={"Sports"}
        banner={width > 767 ? sportsBanner : sportsBannerMb}
        link1={"home"}
        linkText1={"About"}
        linkText2={"Our Company"}
      />

      <div className="my_container">
        <div className="sports-venture">
          {sections.map((section) => (
            <SectionCard
              key={section.id}
              title={section.title}
              content={section.content}
              isTeam={section.isTeam}
              sectionImg={section.sectionImg}
              cardImg={section.cardImg}
              logos={section.logos}
            >
              {section.isTeam && (
                <div className="teams-container">
                  {section.teamData.map((team) => (
                    <TeamCard key={team.id} {...team} />
                  ))}
                </div>
              )}
            </SectionCard>
          ))}
        </div>
      </div>
    </>
  );
};

export default SportsVenture;
